<template>
  <div>
    <rxNavBar v-if="androidFlg == false" title="试岗模板预览" androidOrIOSFlag="true"></rxNavBar>
    <rxNavBar v-else title="试岗模板预览"></rxNavBar>
    <div class="head">
      <div class="search">
        <div class="search-icon" @click="goSearch"></div>
        <input type="text" placeholder="名称" v-model="planName" @keyup.enter="goSearch">
      </div>
      <div class="types">
        <span class="type" @click="isShow = !isShow" id="downList">{{ typeList[number] ? typeList[number].dictionaryTitle : '待提交' }}</span>
        <div :class="isShow?'small-icon-rotate':'small-icon'"></div>
      </div>
      <van-popup v-model="isShow" position="bottom">
        <van-picker
            show-toolbar
            :columns="typeList"
            @cancel="isShow = false"
            :default-index="number"
            value-key="dictionaryTitle"
            @confirm="changeType"
        />
      </van-popup>
    </div>
    <div class="auditDiv">

      <van-pull-refresh v-model="refreshing"  @refresh="onRefresh"   >
        <van-list v-model="loading" :finished="finished" finished-text="" @load="this.onLoad">
          <div class="stateList" v-for="(item,index) in orders" :key="index">
            <div>
              <!--                循环列表头-->
              <!--             0待提交，1待上级审， 2待上上级审，3已审批，4拒绝入职，5重新预约考试，6预约待确认，7成绩待上传，8考试未通过，9入职，10重新学习-->
              <div
                  :class="'top-title top-title-' + item.status">
                {{ item.statusStr }}
              </div>
              <div class="headInfo" >
                <!--                  <span class="headSpan">{{item.planName}}</span>-->
                <!--                  <div class="dot"></div>-->
                {{item.planName}}
              </div>
              <div class="rule3"></div>
              <div class="btnArray">
                <button class="btn2" @click="previewTempate(item)">模板预览</button>
<!--                <button class="btn1" @click="completeTask(item)" v-if="item.status==='0'">学习</button>-->
<!--                <button class="btn1" @click="completeTask(item)" v-if="item.status==='10'">重新学习</button>-->
<!--                <button class="btn1" @click="appointmentExam(item)" v-if="item.status==='3'||item.status==='5'||item.status==='8'">预约考试</button>-->
<!--                <button class="btn1" @click="appointmentExam(item)" v-if="item.status==='6'">修改预约时间</button>-->
                <!--                  <button class="btn1" @click="appointmentExam(item)" v-if="item.status==='8'">预约考试</button>-->
              </div>
            </div>
          </div>

          <!--空状态显示-->
          <common-empty v-if="orders.length===0"></common-empty>
        </van-list>
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
import {
  Button,
  Divider,
  DropdownItem,
  DropdownMenu, Image as VanImage,
  List,
  NavBar,
  Picker,
  Popup,
  PullRefresh,
  Search, Tab, Tabs,
  uploader
} from "vant";
import rxNavBar from "../../../components/rongxun/rx-navBar/navBar";
import {checkAndroid, checkIOS, getStaffId, responseUtil} from "../../../libs/rongxunUtil";
import {queryBaseData, queryProbationPlanList} from "../../../getData/getData";

export default {
  name: "probationPlan",
  components: {
    [Button.name]:Button,
    [Search.name]:Search,
    [DropdownMenu.name]:DropdownMenu,
    [DropdownItem.name]:DropdownItem,
    [Divider.name]:Divider,
    [NavBar.name]: NavBar,
    [List.name]: List,
    rxNavBar,
    [Picker.name]: Picker,
    [Popup.name]: Popup,
    [PullRefresh.name]: PullRefresh,
    [uploader .name]:uploader,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [VanImage.name]: VanImage,
  },
  data(){
    return{
      androidFlg:'',
      loading : true,
      finished: false,
      refreshing:false,
      page:{currentPage:1,numberPage:3,},
      orders:[],
      count:0,
      appointmentShow:false,
      appointmentTime:new Date(),
      item:{},
      notClickable:false,  //预约时间防连点
      planName:'',
      isShow:false,
      number:'0',
      typeList:[],
    }
  },
  mounted() {
    this.initChooseTypes()
    this.checkPhoneorMobel()
    this.finished = false;
    this.loading = true;
    this.refreshing = false
    this.orders=[]
    this.onLoad();
  },
  methods:{
    checkPhoneorMobel() {
      if(checkAndroid()) {
        this.androidFlg = true
      }
      else if(checkIOS()) {
        this.androidFlg = false
      }
    },
    //搜索框查询
    goSearch() {
      this.changeType('',this.number);
    },
    //更改选择类型
    changeType: function (value,index) {
      //将选择的类型放入本地内存中，
      if (index == '') {
        index = 0;
      }
      localStorage.setItem('currentLabel', JSON.stringify(index))
      this.orders = [];
      this.page.currentPage = 1;
      this.finished = false;
      this.isShow = false
      this.number = index;
      this.loading = true;
      this.noContent = false
      this.onLoad();
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;
      this.orders = []
      this.page =  {currentPage:1,numberPage:3}
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.refreshing = false
      this.onLoad();
      responseUtil.alertMsg(this,"刷新成功")
    },
    onLoad() {
      let that = this
      let queryData = {}
      // queryData.type='19'
      queryData.currentPage = that.page.currentPage
      queryData.numberPage = that.page.numberPage
      queryData.staff_id = getStaffId()
      queryData.planName = this.planName
      queryData.status = this.number==3?'':this.number
      queryProbationPlanList(queryData).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          that.count = response.data.data.pageCount
          let list=response.data.data.data?response.data.data.data:[]
          for (let i = 0; i < list.length; i++) {
            that.orders.push(list[i])
          }
          if(that.refreshing){
            that.refreshing = false
          }
          // 加载状态结束
          that.loading = false;
          that.page.currentPage++;
          // 数据全部加载完成
          if (that.orders.length >= that.count) {
            that.finished = true;
          }
          //判断数据是否为空，空则显示空状态图
          // if (that.orders.length == 0) {
          //   that.isEmptuyFlag = true;
          // } else {
          //   that.isEmptuyFlag = false;
          // }
        })
      })
    },

    previewTempate(item){
      this.$router.push({
        name:'previewTempate',
        query:{
          id:item.probationPlan_id,
        }
      });
    },

    initChooseTypes: function () {
      let that = this
      let initData = {}
      initData.dbName = ['description']
      initData.fdName = ['PROBATIONPLANSTATUSMAP']
      queryBaseData(initData).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          //console.log(response.data.data.MAINTRIALPLANRECORDSTATUS)
          let list = response.data.data.PROBATIONPLANSTATUSMAP
          let object = {
            dictionaryName: "all",
            dictionaryTitle: "全部",
            dictionaryValue: "-1",
          }
          list.push(object)

          for (let i = 0; i < list.length; i++) {
            that.typeList.push(list[i])
          }
        })
      })
    },
  }
}
</script>

<style scoped lang="less">
.head {
  width: 100%;
  position: fixed;
  z-index: 8;
  overflow: hidden;
  background: #f8f8f8;
}
.search {
  margin: 10px 20px 0 20px;
  height: 36px;
  border-radius: 30px;
  background-color: rgba(244, 244, 244, 1);
  display: flex;
  align-items: center;
}

.search-icon {
  width: 19px;
  height: 19px;
  margin-left: 9px;
  background-image: url("../../../assets/images/search-icon.png");
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
}
input {
  font-size: 14px;
  margin-left: 10px;
  background-color: rgba(244, 244, 244, 1);
  border: 0;
  width: 250px;
}

input::-webkit-input-placeholder {
  color: rgba(199, 199, 199, 1);
}
.types {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
}

.type {
  text-align: left;
  color: #fe5e3a;
  margin-left: 15px;
  color: rgba(255, 93, 59, 1);
  font-size: 14px;
  font-family: PingFangSC-Regular;
}
.small-icon {
  width: 8px;
  height: 8px;
  margin-left: 5px;
  background-image: url("../../../assets/images/small-icon.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}

.small-icon-rotate {
  width: 8px;
  height: 8px;
  margin-bottom: 5px;
  margin-left: 5px;
  background-image: url("../../../assets/images/small-icon.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  transform: rotate(180deg);
}

//列表
.auditDiv{
  padding-top: 87px;
}
/*    循环列表DIV*/
.stateList{
  border-radius: 0.3rem;
  width: 93%;
  margin-left: 0.35rem;
  position: relative;
  margin-bottom: 0.4rem;
  background-color: #ffffff;
  padding-bottom: 0.5rem;
  padding-top: 0.1rem;

}
/*列表头部信息*/
.headInfo{
  margin-top: 0.4rem;
  margin-bottom: 0.4rem;
  margin-left: 0.4rem;
  font-size: 15px;
  font-weight: bold;
}
.top-title{
  margin: 0;
  width: fit-content;
  padding: 0 5px;
  height: 20px;
  font-size: 10px;
  text-align: center;
  line-height: 22px;
  border-radius: 8px 0;
  color: white;
}
//0待提交，1待上级审， 2待上上级审，3已审批，4拒绝入职，5重新预约考试，6预约待确认，7成绩待上传，8考试未通过，9入职，10重新学习
.top-title-0{
  background: linear-gradient(to right, #7cc7ff, #3596fd);
}
//.top-title-2,.top-title-1{
//  background: linear-gradient(to right, #ffbe72, #ff6c41);
//}
.top-title-1{
  background: linear-gradient(to right, #63cb96, #30d0ac);
}
.top-title-2{
  background: linear-gradient(to right, #bfcbd9, #a8b9cc);
}
//.top-title-2,.top-title-8{
//  background: linear-gradient(to right, #cc004b, #660026);
//}

/*    分割线3*/
.rule3 {
  border: 0.01rem solid #efefef;
  width: 100%;
  height: 0rem;
  margin-top: 0.1rem;
}
/*    下部按钮*/
.btnArray{
  display: flex;
  justify-content: right;
}
.btn1,.btn2{
  /*float: right;*/
  margin-top: 0.35rem;
  margin-right: 0.7rem;
  height: 0.82rem;
  min-width: 2.3rem;
  padding: 0 0.3rem;
  border-radius: 0.2rem;
  border: 0rem solid transparent;
  box-shadow: none;
  line-height: 0.7rem;
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
}
/*按钮*/
.btn1{
  background: -webkit-linear-gradient(left,#FFC274,#FF5D3B);
  color: white;
}
.btn2{
  border: 0.03rem solid transparent;
  background-image: linear-gradient(white,white),
  linear-gradient(to right ,#FFC274,#FF5D3B);
  background-clip: padding-box,border-box;
  background-origin: border-box;
  color: #FF5D3B;
}
</style>